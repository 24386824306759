

























import Vue from 'vue'
import { OrderTrackerInput } from '../../../../shared/types/types'
import { setTrackToFulfillment } from '../../lib/order'
import { data as shippingCarrierList } from '@/assets/carriers.json'

export default Vue.extend({
  name: 'TrackerCard',
  components: {},
  props: {
    title: {
      type: String,
      default: '',
    },
    shouldSubmitTracker: {
      type: Boolean,
      default: false,
    },
    fulfillmentId: {
      type: String,
      required: true,
    },
    orderId: {
      type: String,
      required: true,
    },
    tracker: {
      type: Object,
      default: undefined,
    },
    notifyCustomer: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      trackingNumber: '',
      shippingCarrier: '',
      shippingCarrierList,
    }
  },
  computed: {
    dropdownIcon() {
      return (active: boolean) => (active ? 'menu-up' : 'menu-down')
    },
  },
  watch: {
    shouldSubmitTracker(shouldSubmitTracker) {
      if (shouldSubmitTracker) {
        this.setTrackerToFulfillment()
      }
    },
  },
  created() {
    this.setFields()
  },
  methods: {
    async setTrackerToFulfillment() {
      if (this.trackingNumber && this.shippingCarrier) {
        const trackerArgs = {
          tracker: {
            trackingNumber: this.trackingNumber,
            carrier: this.shippingCarrier,
          },
          notifyCustomer: this.notifyCustomer,
        } as OrderTrackerInput
        this.$emit('is-loading', true)
        try {
          const order = await setTrackToFulfillment(
            this.orderId,
            this.fulfillmentId,
            trackerArgs
          )
          if (order?.fulfillments) {
            this.$emit(
              'update-fulfillment-tracker',
              (order?.fulfillments).find(
                (fulfillment) => fulfillment?.id === this.fulfillmentId
              )
            )
            this.$buefy.toast.open({
              message: 'Tracking has been updated to the fulfillemnt',
              type: 'is-success',
              position: 'is-bottom',
            })
          }
        } catch (error) {
          this.$emit('is-loading', false)
          this.$buefy.toast.open({
            message:
              'Failed to update the tracking details. Please try again later.',
            type: 'is-danger',
            position: 'is-bottom',
          })
        }
      } else {
        this.$emit('update-fulfillment-tracker', {})
        this.$emit('reset-submit')
      }
    },
    setFields() {
      if (this.tracker) {
        this.trackingNumber = this.tracker.trackingNumber
        this.shippingCarrier = this.tracker.carrier
      }
    },
  },
})
